<template>
  <div class="login-container">
    <div class="login-content">
      <div class="login-box">
        <img alt="剧星" src="../assets/logo.png" class="logo" />
        <h2 class="title">剧星达人系统</h2>
        <el-form :model="loginForm" ref="loginForm" :rules="loginRules" @submit.prevent="handleLogin">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="用户名" class="apple-input" @keyup.enter="handleLogin" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="loginForm.password" placeholder="密码" class="apple-input" @keyup.enter.native="handleLogin" />
          </el-form-item>
          <el-button :loading="loading" type="primary" class="apple-button" @click.native.prevent="handleLogin">登录</el-button>

          <el-form-item class="form-item">
            <a :href="forgotPasswordUrl" target="_blank" class="forgot-password-link">找回密码</a>
          </el-form-item>
        </el-form>
      </div>
      <div class="right-image">
        <img src="../assets/right_img.png" alt="登录页装饰图" />
      </div>
    </div>
    <div class="footer">
      <span>© Copyright {{ currentYear }}, VISIONSTAR.CN, 保留所有权利</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备14042765号</a>
      <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">沪公网安备31010702006560号</a>
    </div>
  </div>
</template>

<script>
import { login } from "@/api";

export default {
  name: "Login",
  data () {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
    };
  },
  computed: {
    forgotPasswordUrl () {
      const currentDomain = window.location.origin
      return `https://retrieve.souxiu.cn?oauth_callback=${encodeURIComponent(currentDomain)}`
    },
    currentYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    handleLogin () {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const res = await login(this.loginForm);
            console.log('登录响应:', res);

            if (res.msg === "error") {
              this.$message({
                message: res.sub_msg || "登录失败",
                type: 'error',
                duration: 3000
              });
              return;
            }

            if (res.code !== 200) {
              this.$message({
                message: res.sub_msg || res.msg || "登录失败",
                type: 'error',
                duration: 3000
              });
              return;
            }

            localStorage.setItem("token", res.data.token);
            localStorage.setItem("username", res.data.username);
            this.$router.push("/kol/list");
          } catch (error) {
            console.error('登录错误:', error);

            if (error.response) {
              const errorData = error.response;
              console.log('错误响应数据:', errorData);

              this.$message({
                message: errorData.sub_msg || errorData.msg || "登录失败，请稍后重试",
                type: 'error',
                duration: 3000
              });
            } else {
              this.$message({
                message: error.message || "登录失败，请稍后重试",
                type: 'error',
                duration: 3000
              });
            }
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  background: #fbfbfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  flex-wrap: wrap;
  gap: 30px;
}

.login-box {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  border-radius: 18px;
  backdrop-filter: blur(20px);
}

.right-image {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-image img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}

.footer {
  text-align: center;
  padding: 20px 10px;
  color: #86868b;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer a {
  color: #86868b;
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  color: #1d1d1f;
}

.title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
  color: #1d1d1f;
  text-align: center;
}

.form-item {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: right;
}

.forgot-password-link {
  text-decoration: none;
  color: #f23e31;
  font-size: 14px;
}

:deep(.apple-input .el-input__inner) {
  height: 44px;
  border-radius: 10px;
  border: 1px solid #d2d2d7;
  background: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
}

:deep(.apple-input .el-input__inner:focus) {
  border-color: linear-gradient(90deg, #f75238, #f23e31);
  box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.15);
}

:deep(.apple-button) {
  width: 100%;
  height: 44px;
  margin-top: 15px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f75238, #f23e31);
  border: none;
  font-size: 17px;
  font-weight: 400;
  transition: all 0.3s ease;
}

:deep(.apple-button:hover) {
  background: linear-gradient(0deg, #f75238, #f16d64);
}

:deep(.apple-button:active) {
  background: linear-gradient(90deg, #f75238, #f23e31);
}

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 100%;
  height: auto;
}

/* 响应式样式 */
@media screen and (max-width: 992px) {
  .right-image {
    max-width: 450px;
  }
}

@media screen and (max-width: 768px) {
  .login-content {
    padding: 20px 15px;
    gap: 20px;
  }

  .right-image {
    display: none;
    /* 在中小屏幕上隐藏装饰图 */
  }

  .login-box {
    padding: 20px;
  }

  .title {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 480px) {
  .login-content {
    padding: 15px 10px;
  }

  .login-box {
    padding: 15px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .footer {
    font-size: 10px;
  }

  :deep(.apple-input .el-input__inner) {
    height: 40px;
    font-size: 14px;
  }

  :deep(.apple-button) {
    height: 40px;
    font-size: 15px;
  }
}
</style>
