import request from '@/utils/request'

// 登录
export function login (data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  })
}

// 获取供应商仪表盘数据
export function getSupplierDashboard(params) {
  return request({
    url: '/api/supplier_dashboard',
    method: 'get',
    params
  })
}

// 获取供应商排名数据
export function getSupplierRanking(params) {
  return request({
    url: '/api/supplier_ranking',
    method: 'get',
    params
  })
}

// 获取供应商信息
export function getSuppliers (params) {
  return request({
    url: '/api/suppliers',
    method: 'get',
    params
  })
}

// 获取达人信息
export function getKolInfo (params) {
  return request({
    url: '/api/kol_info',
    method: 'get',
    params
  })
}
// 导出达人信息
export function exportKolInfo (data) {
  return request({
    url: '/api/export_kol',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 获取部门列表
export function getDepartments () {
  return request({
    url: '/api/departments',
    method: 'get'
  })
}

// 获取部门成员
export function getDepartmentMembers (params) {
  return request({
    url: '/api/department/members',
    method: 'get',
    params
  })
}

// 获取客户列表
export function getCustomers (params) {
  return request({
    url: '/api/customers',
    method: 'get',
    params
  })
}

// 获取供应商政策
export function getSupplierPolicies (params) {
  return request({
    url: '/api/supplier_policies',
    method: 'get',
    params
  })
}

export function getKolDetail (params) {
  return request({
    url: '/api/kol_detail',
    method: 'get',
    params
  })
}

// 获取选号车列表
export function getCartList () {
  return request({
    url: '/api/cart/list',
    method: 'get'
  })
}

// 创建选号车
export function createCart (data) {
  return request({
    url: '/api/cart/create',
    method: 'post',
    data
  })
}

// 获取选号车详情
export function getCartDetail (params) {
  return request({
    url: '/api/cart/detail',
    method: 'get',
    params
  })
}

// 向选号车添加达人
export function addKolToCart (data) {
  return request({
    url: '/api/cart/add_kol',
    method: 'post',
    data
  })
}

// 从选号车中移除达人
export function removeKolFromCart (data) {
  return request({
    url: '/api/cart/remove_kol',
    method: 'post',
    data
  })
}

// 更新选号车名称
export function updateCartName (data) {
  return request({
    url: '/api/cart/update_name',
    method: 'post',
    data
  })
}

// 删除选号车
export function deleteCart (data) {
  return request({
    url: '/api/cart/delete',
    method: 'post',
    data
  })
}

// 导出选号车中的达人数据
export function exportCartData (data) {
  return request({
    url: '/api/cart/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 获取供应商订单列表
export function getSupplierOrders (params) {
  return request({
    url: '/api/supplier_orders',
    method: 'get',
    params
  })
}

// 根据供应商ID获取订单
export function getSupplierOrdersById (supplierId, params) {
  return request({
    url: `/api/supplier/${supplierId}/orders`,
    method: 'get',
    params
  })
}

// 获取供应商订单品牌列表
export function getSupplierOrderBrands () {
  return request({
    url: '/api/supplier_orders/brands',
    method: 'get'
  })
}

// 获取供应商订单客户列表
export function getSupplierOrderCustomers () {
  return request({
    url: '/api/supplier_orders/customers',
    method: 'get'
  })
}

// 获取供应商订单详情
export function getSupplierOrderDetail (orderId) {
  return request({
    url: `/api/supplier_order/${orderId}`,
    method: 'get'
  })
}

// 获取角色列表
export function getRoles (params) {
  return request({
    url: '/api/roles',
    method: 'get',
    params
  })
}

// 获取角色详情
export function getRoleDetail (roleId) {
  return request({
    url: `/api/role/${roleId}`,
    method: 'get'
  })
}

// 创建角色
export function createRole (data) {
  return request({
    url: '/api/role',
    method: 'post',
    data
  })
}

// 更新角色
export function updateRole (roleId, data) {
  return request({
    url: `/api/role/${roleId}`,
    method: 'put',
    data
  })
}

// 删除角色
export function deleteRole (roleId) {
  return request({
    url: `/api/role/${roleId}`,
    method: 'delete'
  })
}

// 设置角色菜单
export function setRoleMenus (roleId, data) {
  return request({
    url: `/api/role/${roleId}/menus`,
    method: 'post',
    data
  })
}

// 获取所有菜单
export function getAllMenus () {
  return request({
    url: '/api/menus',
    method: 'get'
  })
}

// 获取用户列表（带角色信息）
export function getUsersWithRoles (params) {
  return request({
    url: '/api/users/roles',
    method: 'get',
    params
  })
}

// 设置用户角色
export function setUserRole (userId, data) {
  return request({
    url: `/api/user/${userId}/role`,
    method: 'put',
    data
  })
}

// 获取达人作品列表
export function getKolVideos(params) {
  return request({
    url: '/api/kol_videos',
    method: 'get',
    params
  })
}

/**
 * 获取达人合作排行榜数据
 * @param {Object} params
 * @param {number} params.page 页码
 * @param {number} params.page_size 每页记录数
 */
export function getKolCooperationRanking(params) {
  return request({
    url: '/api/kol_cooperation_ranking',
    method: 'get',
    params
  })
}
