import axios from "axios";
import { Message } from "element-ui";
import { clearAuthAndRedirectToLogin } from "@/router";
import router from "@/router";

// 获取当前IP地址
const getCurrentIP = () => {
  return window.location.hostname;
};

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://${getCurrentIP()}:9000`
      : // ? 'https://fcback-kol-info.souxiu.cn'
      "https://fcback-kol-info.souxiu.cn",
  timeout: 60000, // Changed to 60s
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 如果是 blob 类型，直接返回响应
    if (response.config.responseType === "blob") {
      return response;
    }

    const res = response.data;

    // 处理不同API的成功状态码
    // 兼容code为200,0,"0"的情况
    if (res.code !== 200 && res.code !== 0 && res.code !== "0") {
      Message.error(res.sub_msg || res.msg || "Error");
      if (res.code === 401) {
        // 跳转到登录页
        router.push("/login");
      }
      return Promise.reject(new Error(res.sub_msg || res.msg || "Error"));
    }
    return res;
  },
  (error) => {
    console.error("API请求错误:", error);
    if (error.response && error.response.status === 401) {
      clearAuthAndRedirectToLogin(router, router.currentRoute.fullPath);
    }
    Message.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
