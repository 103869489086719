<template>
  <div>
    <el-container class="app-wrapper">
      <el-aside width="200px">
        <div class="logo-container">
          <img src="@/assets/visionstar-logo.svg" alt="VisionStar Logo">
        </div>
        <el-menu :default-active="activeMenu" mode="vertical" router background-color="#ffffff" text-color="#1d1d1f" active-text-color="#f23e31" :collapse="false" class="sidebar-menu">
          <!-- 动态渲染菜单 -->
          <template v-for="menu in menuList">
            <!-- 如果没有子菜单 -->
            <el-menu-item v-if="!menu.children || menu.children.length === 0" :key="menu.path" :index="menu.path">
              <i :class="getIconClass(menu.icon)"></i>
              <span>{{ menu.name }}</span>
            </el-menu-item>
            <!-- 如果有子菜单 -->
            <el-submenu v-else :key="menu.path" :index="menu.path">
              <template slot="title">
                <i :class="getIconClass(menu.icon)"></i>
                <span>{{ menu.name }}</span>
              </template>
              <!-- 渲染子菜单 -->
              <el-menu-item v-for="subMenu in menu.children" :key="subMenu.path" :index="subMenu.path">
                <i :class="getIconClass(subMenu.icon)"></i>
                <span>{{ subMenu.name }}</span>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <el-container class="main-container">
        <el-header height="60px">
          <div class="header-right">
            <el-dropdown trigger="hover" class="cart-dropdown" @command="handleCartCommand" ref="cartDropdown" @visible-change="handleDropdownVisibleChange">
              <button class="cart-button-container" @click="handleCartButtonClick">
                <span class="cart-button">
                  <i class="el-icon-shopping-cart-2"></i> 选号车
                </span>
              </button>
              <el-dropdown-menu slot="dropdown" class="cart-dropdown-menu">
                <div class="cart-dropdown-header">
                  <span>我的选号车</span>
                </div>
                <el-dropdown-item v-if="cartList.length === 0" disabled>
                  <span class="empty-cart-tip">暂无选号车，请新建</span>
                </el-dropdown-item>
                <div v-for="cart in cartList" :key="cart.cart_id" class="cart-list-item">
                  <div class="cart-item">
                    <div class="cart-item-info">
                      <span class="cart-name">{{ cart.name }}</span>
                      <span class="cart-count">{{ cart.item_count }}个达人</span>
                    </div>
                    <div class="cart-actions">
                      <el-button type="text" size="mini" @click.stop="viewCartDetail(cart)">
                        详情
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="cart-dropdown-footer">
                  <el-button type="primary" size="small" @click.stop="createNewCart" class="create-cart-btn">
                    <i class="el-icon-plus"></i> 新建选号车
                  </el-button>
                </div>
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown trigger="hover">
              <span class="user-dropdown-link">
                {{ username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleLogout">
                  <i class="el-icon-switch-button"></i>退出
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <div class="content-wrapper">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="新建选号车" :visible.sync="newCartDialogVisible" width="400px">
      <el-form :model="newCartForm" ref="newCartForm" :rules="cartFormRules">
        <el-form-item label="选号车名称" prop="name">
          <el-input v-model="newCartForm.name" placeholder="请输入选号车名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newCartDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitNewCart">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="重命名选号车" :visible.sync="renameCartDialogVisible" width="400px">
      <el-form :model="renameCartForm" ref="renameCartForm" :rules="cartFormRules">
        <el-form-item label="选号车名称" prop="name">
          <el-input v-model="renameCartForm.name" placeholder="请输入选号车名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameCartDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRenameCart">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCartList, createCart, updateCartName, deleteCart, getAllMenus } from '@/api'

export default {
  name: 'Layout',
  data () {
    return {
      // 选号车相关数据
      cartList: [],
      cartLastFetchTime: 0, // 添加最后一次获取时间记录
      cartDataExpireTime: 5 * 60 * 1000, // 数据过期时间，默认5分钟
      newCartDialogVisible: false,
      newCartForm: {
        name: '我的选号车'
      },
      renameCartDialogVisible: false,
      renameCartForm: {
        cart_id: '',
        name: ''
      },
      cartFormRules: {
        name: [
          { required: true, message: '请输入选号车名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      // 菜单相关数据
      menuList: [],
      menuLoading: false,
      // 图标映射表
      iconMap: {
        'dashboard': 'el-icon-s-home',
        'team': 'el-icon-user',
        'ordered-list': 'el-icon-menu',
        'plus-circle': 'el-icon-plus',
        'office-building': 'el-icon-office-building',
        'data-line': 'el-icon-data-line',
        'shopping-cart': 'el-icon-shopping-cart-2',
        'user': 'el-icon-user'
      }
    }
  },
  computed: {
    activeMenu () {
      return this.$route.path
    },
    username () {
      return localStorage.getItem('username') || this.$store.state.user.username || '神秘用户'
    }
  },
  created () {
    // 先检查登录状态，只有登录了才获取选号车列表和菜单
    if (this.isLoggedIn()) {
      this.fetchCartList()
      this.fetchMenus()
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    // 获取图标类名
    getIconClass (icon) {
      return this.iconMap[icon] || 'el-icon-menu' // 默认图标
    },

    // 检查是否已登录
    isLoggedIn () {
      const token = localStorage.getItem('token')
      return !!token
    },

    checkAuth () {
      const token = localStorage.getItem('token')
      if (!token) {
        this.$router.push('/login')
        return false
      }
      return true
    },

    async handleLogout () {
      try {
        await this.$router.push('/login')

        localStorage.removeItem('token')
        localStorage.removeItem('username')
        this.$store.commit('resetState')
        this.$message.success('退出成功')
      } catch (error) {
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        this.$store.commit('resetState')
        window.location.href = '/login'
        console.error('退出失败:', error)
      }
    },

    // 获取菜单数据
    async fetchMenus () {
      this.menuLoading = true
      try {
        const res = await getAllMenus()
        if (res.code === 200) {
          this.menuList = res.data
        }
      } catch (error) {
        console.error('获取菜单失败:', error)
        // 如果是未登录导致的错误，跳转到登录页面
        if (error.response && (error.response.status === 401 || error.response.data?.code === 401)) {
          localStorage.removeItem('token')
          localStorage.removeItem('username')
          this.$router.push('/login')
        } else {
          this.$message.error('获取菜单失败')
        }
      } finally {
        this.menuLoading = false
      }
    },

    // 获取选号车列表，添加缓存逻辑
    async fetchCartList (forceRefresh = false) {
      // 再次检查登录状态，确保在发送请求前用户已登录
      if (!this.isLoggedIn()) {
        this.$router.push('/login')
        return
      }

      // 如果不是强制刷新，且数据未过期，则不重新获取
      const now = Date.now()
      if (!forceRefresh && this.cartList.length > 0 && (now - this.cartLastFetchTime < this.cartDataExpireTime)) {
        return
      }

      try {
        const res = await getCartList()
        if (res.code === 200 && res.data) {
          this.cartList = res.data
          this.cartLastFetchTime = now // 更新最后获取时间
        }
      } catch (error) {
        console.error('获取选号车列表失败:', error)
        // 如果是未登录导致的错误，跳转到登录页面
        if (error.response && (error.response.status === 401 || error.response.data?.code === 401)) {
          localStorage.removeItem('token')
          localStorage.removeItem('username')
          this.$router.push('/login')
        } else {
          this.$message.error('获取选号车列表失败')
        }
      }
    },

    // 监听下拉菜单的显示状态，显示时获取最新数据
    handleDropdownVisibleChange (visible) {
      if (visible && this.isLoggedIn()) {
        // 检查数据是否过期或为空，过期或为空则刷新
        const now = Date.now()
        if (this.cartList.length === 0 || now - this.cartLastFetchTime > this.cartDataExpireTime) {
          this.fetchCartList()
        }
      }
    },

    createNewCart () {
      this.newCartForm.name = '我的选号车'
      this.newCartDialogVisible = true
    },

    async submitNewCart () {
      try {
        const res = await createCart(this.newCartForm)
        if (res.code === 200) {
          this.$message.success('选号车创建成功')
          this.newCartDialogVisible = false
          // 强制刷新选号车列表
          await this.fetchCartList(true)
          // 设置新创建的选号车为当前选中的选号车
          this.activeCart = this.cartList.find(cart => cart.cart_id === res.data.cart_id)
        }
      } catch (error) {
        console.error('创建选号车失败:', error)
        this.$message.error('创建选号车失败')
      }
    },

    showRenameCartDialog (cart) {
      this.renameCartForm.cart_id = cart.cart_id
      this.renameCartForm.name = cart.name
      this.renameCartDialogVisible = true
    },

    async submitRenameCart () {
      try {
        const res = await updateCartName(this.renameCartForm)
        if (res.code === 200) {
          this.$message.success('选号车重命名成功')
          this.renameCartDialogVisible = false
          // 强制刷新选号车列表
          await this.fetchCartList(true)
        }
      } catch (error) {
        console.error('重命名选号车失败:', error)
        this.$message.error('重命名选号车失败')
      }
    },

    confirmDeleteCart (cart) {
      this.$confirm(`确定要删除选号车"${cart.name}"吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const res = await deleteCart({ cart_id: cart.cart_id })
          if (res.code === 200) {
            this.$message.success('选号车删除成功')
            // 强制刷新选号车列表
            await this.fetchCartList(true)
          }
        } catch (error) {
          console.error('删除选号车失败:', error)
          this.$message.error('删除选号车失败')
        }
      }).catch(() => { })
    },

    handleCartCommand (command) {
      // 不再需要选择选号车的功能
      // 保留此方法以防其他命令类型的扩展
    },

    viewCartDetail (cart) {
      if (cart) {
        // 在新窗口打开选号车详情页
        const routeData = this.$router.resolve({
          path: '/cart/detail',
          query: { cart_id: cart.cart_id }
        });
        window.open(routeData.href, '_blank');
      }
    },

    // 处理选号车按钮点击事件
    handleCartButtonClick () {
      if (this.cartList.length === 0) {
        this.createNewCart()
      } else if (this.cartList.length === 1) {
        this.viewCartDetail(this.cartList[0])
      }
    }
  }
}
</script>

<style scoped>
.app-wrapper {
  min-height: 100vh;
  width: 100%;
}

.el-aside {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 201px;
  background: #fff;
}

.logo-container {
  height: 60px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.logo-container img {
  height: 30px;
  width: auto;
}

.main-container {
  margin-left: 200px;
  min-height: 100vh;
}

.el-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  position: fixed;
  top: 0;
  right: 0;
  left: 200px;
  z-index: 1000;
}

.header-right {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-dropdown-link {
  color: #606266;
  cursor: pointer;
  font-size: 14px;
}

.el-main {
  background-color: #f0f2f5;
  padding: 80px 20px 20px;
  min-height: 100vh;
}

.content-wrapper {
  background-color: #ffffff;
  border-radius: 1%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  min-height: calc(100vh - 120px);
}

.sidebar-menu {
  border-right: none;
  width: 200px;
  height: calc(100vh - 80px);
}

.sidebar-menu .el-menu-item,
.sidebar-menu .el-submenu__title {
  height: 50px;
  line-height: 50px;
  padding: 0 20px !important;
  box-sizing: border-box;
}

:deep(.el-menu) {
  border-right: none;
  width: 200px !important;
}

:deep(.el-menu--inline) {
  width: 200px !important;
  padding-left: 0 !important;
}

:deep(.el-submenu .el-menu-item) {
  min-width: 200px !important;
  width: 200px !important;
  padding-left: 55px !important;
  box-sizing: border-box;
}

:deep(.el-submenu__title) {
  padding: 0 20px !important;
  box-sizing: border-box;
}

:deep(.el-menu--popup) {
  min-width: 200px !important;
  width: 200px !important;
}

:deep(.el-menu--popup-right-start) {
  margin-left: 0 !important;
}

.el-menu-item [class^="el-icon-"],
.el-submenu [class^="el-icon-"] {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}

.el-menu-item span,
.el-submenu__title span {
  margin-left: 5px;
}

/* 选号车相关样式 */
.cart-dropdown {
  margin-right: 20px;
  position: relative;
}

.cart-button-container {
  display: inline-block;
  border: 1px solid #dcdfe6;
  border-radius: 20px;
  padding: 6px 15px;
  background-color: #fff;
  transition: all 0.3s;
  margin: 0;
  height: auto;
  cursor: pointer;
  outline: none;
  font-family: inherit;
}

.cart-button-container:hover {
  background-color: #f5f7fa;
  color: #606266;
  border-color: #c6e2ff;
}

.cart-button-container:focus {
  background-color: #fff;
  color: #606266;
  border-color: #dcdfe6;
  outline: none;
  box-shadow: none;
}

.cart-button-container:active {
  border-color: #dcdfe6;
  outline: none;
  box-shadow: none;
}

.cart-button {
  color: #606266;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.cart-button i {
  margin-right: 5px;
  font-size: 16px;
  color: #909399;
}

.active-cart-name {
  margin-left: 5px;
  font-size: 12px;
  color: #f5655a;
}

.cart-dropdown-menu {
  min-width: 250px;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 5px !important;
}

.cart-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid #EBEEF5;
  font-weight: bold;
  color: #303133;
}

.cart-dropdown-footer {
  display: flex;
  justify-content: center;
  padding: 12px 15px;
  border-top: 1px solid #EBEEF5;
}

.create-cart-btn {
  width: 100%;
  border-radius: 20px;
  padding: 8px 20px;
}

.cart-list-item {
  padding: 12px 15px;
  border-bottom: 1px solid #EBEEF5;
  transition: background-color 0.3s;
}

.cart-list-item:hover {
  background-color: #f5f7fa;
}

.cart-list-item:last-child {
  border-bottom: none;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
}

.cart-name {
  font-size: 14px;
  color: #303133;
}

.cart-count {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

.cart-actions {
  display: flex;
  align-items: center;
}

.cart-actions .el-button {
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 12px;
  background-color: #f5f7fa;
  border: none;
}

.cart-actions .el-button:hover {
  color: #f5655a;
  background-color: #f5f7fa;
}

.empty-cart-tip {
  color: #909399;
  font-size: 14px;
}
</style>