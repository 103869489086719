import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

// 静态路由，不需要权限
const staticRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    component: Layout,
    children: [
      // 基础路由，确保系统能正常运行
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
      {
        path: "home",
        name: "Dashboard",
        component: () => import("@/views/Dashboard"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "organization",
        name: "Organization",
        component: () => import("@/views/Organization"),
        meta: {
          title: "我的组织",
        },
      },
      {
        path: "organization/user-roles",
        name: "UserRoles",
        component: () => import("@/views/role/UserRoles.vue"),
        meta: {
          title: "用户角色管理",
        },
      },
      {
        path: "role/list",
        name: "RoleList",
        component: () => import("@/views/role/RoleList.vue"),
        meta: {
          title: "角色列表",
        },
      },
      // 其他路由，确保系统能正常运行
      {
        path: "customers/pool",
        name: "CustomerPool",
        component: () => import("@/views/CustomerPool"),
        meta: {
          title: "客户池",
        },
      },
      {
        path: "customers/data",
        name: "CustomerData",
        component: () => import("@/views/CustomerData"),
        meta: {
          title: "客户数据",
        },
      },
      {
        path: "suppliers/search",
        name: "SupplierSearch",
        component: () => import("@/views/suppliers/Search.vue"),
        meta: {
          title: "查找供应商",
        },
      },
      {
        path: "suppliers/annual",
        name: "AnnualSuppliers",
        component: () => import("@/views/suppliers/Annual.vue"),
        meta: { title: "年度供应商" },
      },
      {
        path: "suppliers/analysis",
        name: "SupplierAnalysis",
        component: () => import("@/views/suppliers/Analysis.vue"),
        meta: { title: "供应商分析" },
      },
      {
        path: "suppliers/cost-analysis",
        name: "CostAnalysis",
        component: () => import("@/views/suppliers/CostAnalysis.vue"),
        meta: { title: "成本类型分析" },
      },
      {
        path: "suppliers/orders",
        name: "SupplierOrders",
        component: () => import("@/views/suppliers/Orders.vue"),
        meta: {
          title: "供应商订单查询",
        },
      },
      {
        path: "suppliers/order-detail",
        name: "OrderDetail",
        component: () => import("@/views/suppliers/OrderDetail.vue"),
        meta: {
          title: "订单详情",
        },
      },
      {
        path: "kol/ranking",
        name: "KolRanking",
        component: () => import("@/views/kol/KolRanking.vue"),
        meta: {
          title: "达人合作排行",
        },
      },
      {
        path: "kol/list",
        name: "KolList",
        component: () => import("@/views/kol/KolList.vue"),
        meta: {
          title: "达人库",
        },
      },
      {
        path: "kol/detail",
        name: "KolDetail",
        component: () => import("@/views/kol/KolDetail.vue"),
        meta: {
          title: "达人详情",
        },
      },
      {
        path: "cart/detail",
        name: "CartDetail",
        component: () => import("@/views/cart/CartDetail.vue"),
        meta: {
          title: "选号车详情",
        },
      },
      {
        path: "home/annual",
        name: "DashboardAnnual",
        component: () => import("@/views/dashboard/Annual.vue"),
        meta: { title: "年框看板" },
      },
      {
        path: "home/kol",
        name: "DashboardKOL",
        component: () => import("@/views/dashboard/KOL.vue"),
        meta: { title: "达人看板" },
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes: staticRoutes,
});

// 添加清除认证并跳转登录页的辅助函数
export const clearAuthAndRedirectToLogin = (router, redirectPath) => {
  localStorage.removeItem("token");
  router.push({
    path: "/login",
    query: { redirect: redirectPath },
  });
};

router.beforeEach((to, from, next) => {
  if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
    window.location.href = window.location.href.replace('http:', 'https:');
    return;
  }
  const baseTitle = "剧星传媒";
  const pageTitle = to.meta.title || "管理系统";
  document.title = `${pageTitle} - ${baseTitle}`;
  if (to.path !== '/login') {
    if (!localStorage.getItem("token")) {
      clearAuthAndRedirectToLogin(router, to.fullPath);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
