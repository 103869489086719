import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import { InfiniteScroll } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 全局配置 Element UI 组件尺寸为 mini
Vue.use(ElementUI, {
  size: 'mini'
})

// 使用无限滚动指令
Vue.use(InfiniteScroll)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
